<template>
  <div>
    <div class="d-flex justify-content-between mb-1">
      <b-button
        v-b-toggle.collapse-1
        variant="outline-primary"
      >
        {{ t('Filters') }}
      </b-button>

      <b-button
        v-if="checkPermission(permissions.ADD_COMPANY_USER)"
        variant="primary"
        @click="redirectToCreate"
      >
        <feather-icon icon="PlusIcon" />
        {{ t('Add User') }}
      </b-button>
    </div>
    <b-collapse
      id="collapse-1"
      class="mt-2"
    >
      <user-filter
        :form="form"
        @searchData="recieveData"
        @filterData="filterData"
      />
    </b-collapse>
    <b-card>
      <b-table
        sticky-header
        responsive="true"
        :items="items.data"
        :fields="userFields"
        @row-contextmenu="contextmenu"
        @sort-changed="sortChanged"
      >
        <template #cell(companyuserid)="data">
          <span class="">{{ data.item.companyuserid }}</span>
        </template>
        <template #cell(profile_pic)="data">
          <span
            class=""
          ><b-avatar
            size="40"
            variant="light-primary"
            :src="data.item.profile_pic_url"
            class="badge-minimal"
          /></span>
        </template>
        <template #cell(username)="data">
          <span class="">{{ data.item.username }}</span>
        </template>
        <template #cell(email)="data">
          <span class="">{{ data.item.email }}</span>
        </template>
        <template #cell(firstname)="data">
          <span class="">{{ data.item.firstname.charAt(0).toUpperCase() + data.item.firstname.slice(1) }}</span>
        </template>
        <template #cell(lastname)="data">
          <span class="">{{ data.item.lastname.charAt(0).toUpperCase() + data.item.lastname.slice(1) }}</span>
        </template>
        <template #cell(city_name)="data">
          <span class="">{{ data.item.city_name }}</span>
        </template>
        <template #cell(usertype)="data">
          <span>{{ data.item.usertype }}</span>
        </template>
        <template #cell(status)="data">
          <span
            v-if="data.item.status === constants.USER_STATUS_ACTIVE"
            class="text-success cursor-pointer"
            @click="changeStatus(data.item.status, data.item.companyuserid )"
          >Active</span>
          <span
            v-if="data.item.status === constants.USER_STATUS_INACTIVE"
            class="text-danger cursor-pointer"
            @click="changeStatus(data.item.status, data.item.companyuserid )"
          >In-Active</span>
        </template>
        <template
          v-if="checkPermission(permissions.RESET_PASSWORD)"
          #cell(reset_password)="data"
          class="text-center"
        >
          <feather-icon
            class="cursor-pointer"
            icon="RefreshCwIcon"
            @click.prevent="resetPassword(data.item.companyuserid)"
          />
        </template>
        <template
          v-if="items.total == 0"
          #table-caption
        >
          <div
            class="text-center"
          >
            {{ t('No Record Found') }}
          </div>
        </template>
      </b-table>
      <b-row v-if="items.total">

        <b-col
          lg="4"
          class="py-2 d-flex"
        >
          <span class="pr-2 pt-10 align-self-center">
            {{ t('Total Entries') + " " + items.total }}
          </span>

          <b-form-group class="mb-0 align-self-center">
            <label class="d-inline-block text-sm-left mr-50">{{ t('Per page') }}</label>
            <b-form-select
              id="perPageSelect"
              v-model="form.size"
              size="sm"
              :options="tableFilter.pageOptions"
              class="w-70"
              @change="getUserPerPage"
            />
          </b-form-group>

        </b-col>
        <b-col lg="8">
          <pagination
            :data="items"
            :limit="2"
            class="pt-1 justify-content-end"
            @pagination-change-page="getResults"
          />
        </b-col>

      </b-row>
    </b-card>
    <reset-password
      ref="resetPassword"
      :form="resetPasswordId"
    />
    <vue-context
      ref="UserContextMenu"
    >
      <li>
        <b-link
          v-for="data in menuData"
          :key="data.text"
          class="d-flex align-items-center"
          @click="optionClicked(data.id)"
        >
          <feather-icon
            :icon="data.icon"
            size="16"
          />
          <span class="ml-75">{{ data.text }}</span>
        </b-link>
      </li>
    </vue-context>
  </div>
</template>

<script>
import {
  BTable, BCard, BButton, BLink, BCollapse, VBToggle, BRow, BCol, BAvatar, BFormSelect, BFormGroup,
} from 'bootstrap-vue'
import VueContext from 'vue-context'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils } from '@core/libs/i18n'
import axios from '@/utils/axiosCompany'
import store from '@/store'
import constants from '@/constants'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import ResetPassword from './ResetPassword.vue'
import UserFilter from './UserFilter.vue'
import checkPermissions from '@/utils/checkPermissons'
import {
  /*  userTabelFieldsNoPassword, */ tableFilter, showLoader, hideLoader,
} from '@/utils/common'
import permissions from '@/permissions'

export default {
  name: 'UserList',
  components: {
    BLink,
    BCol,
    BCollapse,
    BRow,
    BCard,
    BButton,
    BTable,
    VueContext,
    FeatherIcon,
    ResetPassword,
    UserFilter,
    BAvatar,
    BFormSelect,
    BFormGroup,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  setup() {
    const { t } = useUtils()
    return { t, permissions }
  },
  data() {
    return {
      resetPasswordId: 0,
      constants,
      tableFilter,
      form: {
        params: {
          user_type: '',
          city: '',
          city_name: '',
          status: '',
          email: '',
          name: '',
          username: '',
          services: '',
        },
        size: '10',
        totalElements: '',
        totalPages: '',
        sortBy: 'companyuserid',
        sortOrder: 'desc',
      },
      items: [],
      updateStatus: '',
    }
  },
  computed: {
    menuData() {
      return [
        { id: 1, icon: 'EditIcon', text: this.$i18n.t('Edit User') },
      ]
    },
    userFields() {
      return [
        { key: 'companyuserid', label: this.$i18n.t('Id'), sortable: true },
        { key: 'profile_pic', label: this.$i18n.t('Photo') },
        { key: 'username', label: this.$i18n.t('Username'), sortable: true },
        { key: 'email', label: this.$i18n.t('Email'), sortable: true },
        { key: 'firstname', label: this.$i18n.t('FirstName'), sortable: true },
        { key: 'lastname', label: this.$i18n.t('LastName'), sortable: true },
        { key: 'city_name', label: this.$i18n.t('City'), sortable: true },
        { key: 'usertype', label: this.$i18n.t('Usertype'), sortable: true },
        { key: 'status', label: this.$i18n.t('Status'), sortable: true },
      ]
    },
  },
  mounted() {
    if (checkPermissions(permissions.RESET_PASSWORD)) {
      this.userFields.push({ key: 'reset_password', label: this.$i18n.t('Reset Password') })
    }
    this.getUsers()
  },
  created() {
    // this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)
  },
  methods: {
    getUserPerPage() {
      this.getUsers()
    },
    sortChanged(val) {
      this.form.sortBy = val.sortBy
      this.form.sortOrder = val.sortDesc ? 'desc' : 'asc'
      this.getUsers()
    },
    checkPermission(val) {
      return checkPermissions(val)
    },
    recieveData(val) {
      this.items = val
    },
    filterData(val) {
      this.form = val
    },
    resetPassword(id) {
      this.resetPasswordId = id
      this.$bvModal.show('resetPassword')
    },
    redirectToCreate() {
      this.$router.push('/company/create-user')
    },
    contextmenu(item, index, event) {
      event.preventDefault()
      this.values = item
      this.$refs.UserContextMenu.open(event, item)
    },
    optionClicked(id) {
      // eslint-disable-next-line eqeqeq
      if (id == 1) {
        this.$root.$router.push(`/company/user/edit-user/${this.values.encode_companyuserid}`)
      }
    },
    async getUsers() {
      showLoader()
      await store.dispatch('CompanyuserManage/getUsers', this.form).then(response => {
        if (response.data.code === '200') {
          this.items = response.data.data
          this.form.totalElements = response.data.data.total
        }
        hideLoader()
      }).catch(e => {
      // eslint-disable-next-line eqeqeq
        if (e.msg == 'Unauthenticated.') {
          this.$router.back()
          hideLoader()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'No Permission',
              icon: 'Danger',
              variant: 'danger',
              text: '',
            },
          })
        }
      })
    },
    changeStatus(status, id) {
      // eslint-disable-next-line eqeqeq
      if (status == 'A') {
        this.$swal({
          title: this.$i18n.t('Are you sure ?'),
          text: this.$i18n.t('You want to Deactivate this User!'),
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.$i18n.t('Cancel'),
          confirmButtonText: this.$i18n.t('Yes'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            store.dispatch('CompanyuserManage/changeStatus', { status: 'I', companyuserid: id }).then(response => {
            // eslint-disable-next-line eqeqeq
              if (response.data.code == constants.SUCCESS) {
                this.getUsers()
                // store.dispatch('CompanyuserManage/getUsers').then(res => {
                //   if (res.data.code === '200') {
                //     this.items = res.data.data
                //     this.$swal({
                //       icon: 'success',
                //       title: this.$i18n.t('Success'),
                //       text: this.$i18n.t('User is successfully Deactivated'),
                //       customClass: {
                //         confirmButton: 'btn btn-success',
                //       },
                //     })
                //   }
                // })
              }
            })
          }
        })
      } else {
        this.$swal({
          title: this.$i18n.t('Are you sure ?'),
          text: this.$i18n.t('You want to Activate this User!'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$i18n.t('Yes'),
          cancelButtonText: this.$i18n.t('Cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            store.dispatch('CompanyuserManage/changeStatus', { status: 'A', companyuserid: id }).then(response => {
            // eslint-disable-next-line eqeqeq
              if (response.data.code == constants.SUCCESS) {
                this.getUsers()
                // store.dispatch('CompanyuserManage/getUsers').then(res => {
                //   if (res.data.code === '200') {
                //     this.items = res.data.data
                //     this.$swal({
                //       icon: 'success',
                //       title: this.$i18n.t('Success'),
                //       text: this.$i18n.t('User is successfully Activated'),
                //       customClass: {
                //         confirmButton: 'btn btn-success',
                //       },
                //     })
                //   }
                // })
              }
            })
          }
        })
      }
    },
    async getResults(page = 1) {
      showLoader()
      // eslint-disable-next-line no-undef
      await axios.post(`/company-user/list?page=${page}`, this.form, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
        .then(response => {
          this.items = response.data.data
          hideLoader()
        })
      hideLoader()
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-context.scss';
.cursor-pointer{
  cursor: pointer;
}
</style>
